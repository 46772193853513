<template>
  <div class="design-container">
    <div class="left-column">
      <div class="header-content">
        <img src="@/assets/wifi-block-logo.svg" />
      </div>
      <div class="main-content">
        <slot name="main-content"></slot>
      </div>
    </div>
    <div class="right-column" :style="{ 'background-image': 'url(' + require('@/assets/marble-bkg.jpg') + ')' }">
      <slot name="right-preview"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DesignContainer',
};
</script>

<style scoped lang="scss">
.design-container {
  background-color: #eeeeee;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  height: 100%;
  position: relative;

  .left-column {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 40%;
    max-width: 500px;

    .header-content {
      height: 80px;
      display: flex;

      img {
        display: block;
        max-height: 70%;
        align-self: center;
        justify-self: center;
        margin-left: 100px;
      }
    }

    .main-content {
      background-color: #3a3a39;
      flex-grow: 1;
      padding: 20px;
      padding-left: 10%;
      width: 100%;
      color: #ffffff;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      box-sizing: border-box;
    }

    .step-2 {
      justify-self: flex-end;
      align-self: flex-start;
      width: 100%;

      .swatches {
        justify-content: space-around;
        margin-top: 32px;

        .swatch {
          cursor: pointer;

          img {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .right-column {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-grow: 1;
    width: 60%;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bottom-bar {
    width: 100%;
    height: 50px;
    background-color: #3a3a39;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (min-width: 1000px) {
      display: none;
    }

    input {
      border: none;
      background-color: #eeeeee;
      font-size: 16px;
      width: 90%;
      height: 33px;
      border-radius: 6px;
      padding-left: 20px;
      margin: 5px 0;

      &:focus {
        outline: 0;
        border: 2px solid #f1aa49;
      }
    }

    .bottom-bar-menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      width: 100%;

      button {
        display: block;
        justify-self: flex-end;
      }
    }
  }

  button {
    background: #f1aa49;
    font-size: 16px;
    border: none;
    color: #3a3a39;
    font-weight: 600;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
    }

    @media only screen and (max-width: 1000px) {
    }
  }

  input {
    border: 2px solid transparent;
    background-color: #eeeeee;
    font-size: 16px;
    width: 300px;
    height: 33px;
    border-radius: 6px;
    padding-left: 20px;
    margin: 5px 0;

    &.font-size-input {
      margin-left: 20px;
      width: 40px;
      padding-left: 10px;
    }

    &:focus {
      outline: 0;
      border: 2px solid #f1aa49;
    }
  }

  .search-results {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    max-height: 200px;
    width: 90%;
    overflow: scroll;
    align-self: flex-start;

    img {
      margin: 14px auto;

      min-width: 48px;
      max-width: 48px;
      min-height: 48px;
      max-height: 48px;

      background-color: white;
      border-radius: 6px;
      padding: 6px;
      box-sizing: border-box;
      cursor: pointer;
      border: 2px solid #b5b5b5;

      &.selected {
        border: 2px solid #f1aa49;
      }

      &:hover {
        background-color: #eeeeee;
      }
    }
  }

  .text-preview {
    position: absolute;
    top: 50px;
    font-size: 60px;
    // font-family: 'Open Sans', sans-serif;
    font-family: 'WoodBurn';
    width: 350px;
  }

  .icon-preview {
    position: absolute;
    width: 175px;
    height: 175px;
    bottom: 75px;
    filter: contrast(0.8);

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .step-position {
    align-self: flex-start;
  }
  .step-title {
    align-self: flex-start;
    font-size: 24px;
  }
}
</style>
