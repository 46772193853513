<template>
  <DesignContainer>
    <template slot="main-content">
      <div>
        <h1>Complete your purchase</h1>

        <h2>Your Info</h2>
      </div>
      <div>
        <label>Name</label>
        <input type="text" v-model="name" autocomplete="name" />
        <br />

        <label>Email</label>
        <input type="text" v-model="email" autocomplete="email" />
        <br />
      </div>

      <h2>Shipping</h2>
      <label>Your Address</label>
      <vue-google-autocomplete
        id="map"
        class="form-control"
        placeholder="Start typing"
        v-on:placechanged="getAddressData"
        :enable-geolocation="true"
        country="us"
        autocomplete="new-password"
      ></vue-google-autocomplete>

      <label>Apartment or Suite</label>
      <input type="text" />

      <h2>Payment</h2>
      <Card
        class="stripe-card"
        ref="stripe-card"
        :class="{ complete }"
        stripe="pk_test_xk6NgWdHWQ2FopfcV0wJaTWP00jLv7knHL"
        :options="stripeOptions"
        @change="complete = $event.complete"
      />

      <div class="complete-order">
        <div class="pricing">
          Order total:
          <span class="crossout">$60</span>
          <span class="discount-price">$34.99</span>
          <span class="percent-off">(44% off!)</span>
        </div>
        <button class="complete-order" :class="{ complete }" @click="pay3">Buy Now</button>
        <!-- <img class="stripe-power" src="@/assets/powered_by_stripe.svg" /> -->
      </div>
      <!-- <button class="pay-with-stripe" @click="pay">Pay with credit card</button> -->
      <!-- <div id="payment-request-button"></div> -->
    </template>

    <template slot="right-preview">
      <LivePreview />
    </template>
  </DesignContainer>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { Card } from 'vue-stripe-elements-plus';

import axios from 'axios';
import DesignContainer from '@/components/DesignContainer';
import LivePreview from '@/components/LivePreview';
import config from '@/utils/config';

export default {
  name: 'CheckoutView',
  components: { DesignContainer, VueGoogleAutocomplete, Card, LivePreview },
  data() {
    return {
      name: 'Jonathan Gaudette',
      email: 'jgaudette@gmail.com',
      complete: false,
      stripeOptions: {},
      shipping: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
      },
    };
  },
  mounted() {
    this.initializePaymentAPI();
  },
  methods: {
    async pay4() {
      this.$stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Demo total',
          amount: 1000,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestShipping: true,
      });
    },

    getAddressData(address) {
      /**
       * "street_number": "10",
       * "route": "High Street",
       * "locality": "Shrewsbury",
       * "administrative_area_level_1": "MA",
       * "country": "United States",
       * "postal_code": "01545",
       * "latitude": 42.30727,
       * "longitude": -71.6894997
       */

      this.shipping = {
        addressLine1: `${address.street_number} ${address.route}`,
        city: address.locality,
        state: address.administrative_area_level_1,
        zip: address.postal_code,
      };
    },

    /**
     * Working without server -- no shipping would need to add that on
     */
    async pay3() {
      await this.$stripe.redirectToCheckout({
        successUrl: `${this.$baseUrl}/checkout/success?session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${this.$baseUrl}/checkout/cancel`,
        shippingAddressCollection: {
          allowedCountries: ['US', 'CA'],
        },
        items: [
          {
            sku: 'sku_GLcVODiLLI0Dms',
            quantity: 1,
          },
        ],
      });
    },

    async pay() {
      let checkoutSessionResp = await axios.post(
        `${config.getValue('VUE_APP_BACKEND_HOST')}/payment/checkout`,
        this.$data,
      );

      const { error } = await this.$stripe.redirectToCheckout({
        sessionId: checkoutSessionResp.data.id,
      });
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.

      if (error) {
        // console.log('ERROR: ' + error.message);
      }
    },

    async pay5() {
      // let data = await createToken();

      let intentResp = await axios.get('http://localhost:4100/payment/intent');

      let client_secret = intentResp.data.client_secret;
      this.$stripe.confirmCardPayment(client_secret);
      // debugger;

      // debugger;
      // let checkoutSessionResp = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/payment/checkout`, {
      //   token: data.token,
      // });
    },

    async initializePaymentAPI() {
      const paymentRequest = this.$stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Wifi Block Personalized Item',
          amount: 4999,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestShipping: true,
        shippingOptions: [
          // The first shipping option in this list appears as the default
          // option in the browser payment interface.
          {
            id: 'free-shipping',
            label: 'Free shipping',
            detail: 'Hand crafted and arrives in 7 to 14 days',
            amount: 0,
          },
        ],
      });

      const elements = this.$stripe.elements();
      const prButton = elements.create('paymentRequestButton', {
        paymentRequest,
      });

      // Check the availability of the Payment Request API first.
      let canMakePaymentResult = await paymentRequest.canMakePayment();
      if (canMakePaymentResult) {
        prButton.mount('#payment-request-button');
      } else {
        document.getElementById('payment-request-button').style.display = 'none';
        return;
      }

      paymentRequest.on('token', async ev => {
        // Send the token to your server to charge it!
        const response = await fetch('/charges', {
          method: 'POST',
          body: JSON.stringify({ token: ev.token.id }),
          headers: { 'content-type': 'application/json' },
        });

        if (response.ok) {
          // Report to the browser that the payment was successful, prompting
          // it to close the browser payment interface.
          ev.complete('success');
        } else {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete('fail');
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.stripe-card {
  border: none;
  background-color: #eeeeee;
  font-size: 16px;
  width: 90%;
  max-width: 400px;
  height: 33px;
  border-radius: 6px;
  padding-left: 20px;
  margin: 10px 0;
  padding-top: 7px;

  &:focus {
    outline: 0;
    border: 2px solid #f1aa49;
  }
}
.stripe-card.complete {
  border: 2px solid #f1aa49;
}

.complete-order {
  justify-self: flex-end;
  width: 100%;
  margin-top: auto;
}
button.complete-order {
  font-weight: 700;
  font-size: 20px;
  background-color: gray;

  &.complete {
    background-color: $tulip-tree;
  }
}
.stripe-power {
  display: block;
  margin-left: auto;
}
</style>
